import React, { useState, useEffect } from "react";
import { Wheel } from "react-custom-roulette";

const Roulette = ({ data }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [rouletteData, setRouletteData] = useState(data);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  useEffect(() => {
    const addShortString = data.map((item) => {
      return {
        completeOption: item.text,
        option:
          item.text.length >= 30
            ? item.text.substring(0, 30).trimEnd() + "..."
            : item.text
      };
    });
    setRouletteData(addShortString);
  }, [data]);

  return (
    <>
      <div align="center" className="roulette-container">
        <Wheel
          mustStartSpinning={mustSpin}
          spinDuration={[0.2]}
          prizeNumber={prizeNumber}
          data={rouletteData}
          outerBorderColor={["#ccc"]}
          outerBorderWidth={[9]}
          innerBorderColor={["#f2f2f2"]}
          radiusLineColor={["tranparent"]}
          radiusLineWidth={[1]}
          textColors={["#f5f5f5"]}
          textDistance={55}
          fontSize={[20]}
          // Roulette emotions background 
          backgroundColors={[
            "#edc900", // Alegria = Amarelo vibrante (FFDC00)
            "#007BFF", // Tristeza = Azul calmo (175fa9)
            "#DC3545", // Raiva = Vermelho intenso
            "#28A745", // Nojinho = Verde claro
            "#6C757D", // Medo = Cinza neutro
            "#fc9002", // Ansiedade = Laranja (FFC107)
            "#17A2B8", // Inveja = Azul-turquesa
            "#6F42C1", // Tédio = Roxo
            "#E83E8C", // Vergonha = Rosa
            // "#e5177b",
            // "#be1180",
            // "#871f7f"
          ]}
          onStopSpinning={() => {
            setMustSpin(false);
          }}
        />
        <button className="button roulette-button" onClick={handleSpinClick}>
          Girar
        </button>
      </div>
      <br />
      <button
        className="prize-message"
        onClick={handleSpinClick}
        disabled={mustSpin}
      >
        {!mustSpin ? rouletteData[prizeNumber].completeOption : "Sorteando..."}
      </button>
    </>
  );
};

export default Roulette;
